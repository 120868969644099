import { useLanguage } from "@/components/context/LanguageContext";
import { useNavigate } from "react-router-dom";
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
  } from "@/components/ui/select";
  import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
    CardFooter,
    CardDescription,
  } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { useState } from "react";


const SupportSettings = () => {
    const { language } = useLanguage();
    const navigate = useNavigate();
    const [supportList, setSupportList] = useState([
        {
            id: "1",
            title_en:"On Boarding",
            title_ar : "التسجيل",
            linked_department: "1",
        },
        {
            id: "2",
            title_en:"Off Boarding",
            title_ar : "انهاء",
            linked_department: "2",
        },
        {
            id: "3",
            title_en:"IT Support",
            title_ar : "الدعم التقني",
            linked_department: "3",
        },
        {
            id: "4",
            title_en:"HR Support",
            title_ar : "الدعم الموارد البشرية",
            linked_department: "4",
        },
        {
            id: "5",
            title_en:"Finance Support",
            title_ar : "الدعم المالي",
            linked_department: "5",
        },
    ]);
    const [departmentList, setDepartmentList] = useState([
        {
            id: "1",
            title_en:"IT",
            title_ar : "تقنية المعلومات",
        },
        {
            id: "2",
            title_en:"HR",
            title_ar : "الموارد البشرية",
        },
        {
            id: "3",
            title_en:"Finance",
            title_ar : "المالية",
        },
    ]);
    const [updateSupport, setUpdateSupport] = useState<any>([]);


    const handleUpdateSupport = (value:any, id:any) => {
        const updatedSupport = supportList.map((support) => {
            if(support.id === id) {
                return {
                    ...support,
                    linked_department: value,
                }
            }
            return support;
        });
        setUpdateSupport(updatedSupport);
    };

  return (
    <div
        dir={language === "en" ? "ltr" : "rtl"}
    >
      
        <Card
            className="w-11/12 mx-auto my-10 lg:w-1/2"
        >

            <CardHeader>
                <Button
                    onClick={() => navigate('/support')}
                    variant={"secondary"}
                    className="w-full"
                >
                    {language === "en" ? "Back" : "رجوع"}
                </Button>
            <CardTitle
                className="text-center pt-4"
            >
                {language === "en" ? "Support Settings" : "إعدادات الدعم"}
            </CardTitle>
            </CardHeader>
            <CardContent
                className="text-start space-y-4"
            >
                {
                    supportList.map((support) => (
                        <div key={support.id}>
                            <CardDescription>
                                {language === "en" ? support.title_en : support.title_ar}
                            </CardDescription>
                            <Select
                                defaultValue={support.linked_department}
                                onValueChange={(value) => handleUpdateSupport(value, support.id)}
                            >
                                <SelectTrigger>
                                    <SelectValue
                                        placeholder={
                                            language === "en" ? "Select Department" : "اختر القسم"
                                        }
                                    /> 
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectGroup>
                                        {
                                            departmentList.map((department) => (
                                                <SelectItem
                                                    value={department.id}
                                                    key={department.id}
                                                >
                                                    {language === "en" ? department.title_en : department.title_ar}
                                                </SelectItem>
                                            ))
                                        }
                                    </SelectGroup>
                                </SelectContent>
                            </Select>
                        </div>
                    ))
                }
            </CardContent>
            <CardFooter>
            <Button
                className="w-full"
                disabled={updateSupport.length === 0}
                
            >
                {language === "en" ? "Update" : "تحديث"}
            </Button>
            </CardFooter>
        </Card>
    </div>
  );
}

export default SupportSettings;