import { useLanguage } from "@/components/context/LanguageContext";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import {
  PermissionGroups,
  Permissions,
} from "@/services/permission-group-services";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  CardFooter,
} from "@/components/ui/card";
import { Checkbox } from "@/components/ui/checkbox";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import LoadingScreen from "@/components/loading/loading-screen";
import LoadingIcon from "@/components/loading/loading-icon";
import {
  Table,
  TableBody,
  TableCell,
  TableCaption,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";

const PermissionGroupCreate = () => {
  const { language } = useLanguage();
  const navigate = useNavigate();
  const [formError, setFormError] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [PermissionGroupData, setPermissionGroupData] = useState<any>({
    name: "",
    permissions: [],
  });

  useEffect(() => {
    document.title = "Lazem GO - Create Permission Group";
  }, []);

  const { data: permissionData, isLoading: permissionLoading } = useQuery({
    queryKey: ["PermissionGroupList"],
    queryFn: () =>
      Permissions.list()
        .then((res: any) => {
          return sortPermissionsByEndpoint(res);
        })
        .catch((error) =>{}),
    refetchOnWindowFocus: false,
  });

  const handelSubmit = async () => {
    if (!checkForm()) return;
    setIsSubmit(true);
    await PermissionGroups.create(PermissionGroupData)
      .then((response) => {
        setIsSubmit(false);
        navigate("/permission-group/id/" + response.id);
      })
      .catch((error) => {
        setIsSubmit(false);
      });
  };

  const checkForm = () => {
    if (PermissionGroupData.name === "") {
      setFormError(
        language === "en"
          ? "Please enter the permission group name"
          : "الرجاء إدخال اسم مجموعة الصلاحيات"
      );
      scrollTo(0, 0);
      return false;
    }
    return true;
  };

  const sortPermissionsByEndpoint = (data: any) => {
    const endpoints: any = {};

    data.forEach((permission: any) => {
      const [action, endpoint] = permission.codename.split("_");

      if (!endpoints[endpoint]) {
        endpoints[endpoint] = {
          view: null,
          add: null,
          change: null,
          delete: null,
        };
      }

      if (action === "view") {
        endpoints[endpoint].view = permission;
      } else if (action === "add") {
        endpoints[endpoint].add = permission;
      } else if (action === "change") {
        endpoints[endpoint].change = permission;
      } else if (action === "delete") {
        endpoints[endpoint].delete = permission;
      }
    });

    return endpoints;
  };

  const handlePermissionChange = (isChecked:any, permission:any) => {
    if (isChecked) {
      setPermissionGroupData({
        ...PermissionGroupData,
        permissions: [...PermissionGroupData.permissions, permission.id],
      });
    } else {
      setPermissionGroupData({
        ...PermissionGroupData,
        permissions: PermissionGroupData.permissions.filter(
          (id:any) => id !== permission.id
        ),
      });
    }
  };

  return (
    <div 
      dir={language === "en" ? "ltr" : "rtl"}>
      {permissionLoading && <LoadingScreen />}

      <Card className="w-full lg:w-4/5 mx-auto mt-10">
        <Button
          onClick={() => navigate("/permission-group")}
          className="my-4 w-4/5"
          variant={"secondary"}
        >
          {language === "en" ? "Back" : "رجوع"}
        </Button>
        <CardHeader>
          <CardTitle>
            {language === "en"
              ? "Create Permission Group"
              : "إنشاء مجموعة صلاحيات"}
          </CardTitle>
        </CardHeader>
        <CardContent className="text-start">
          <CardDescription>
            <div className="mx-auto">
              <Label>
                {language === "en"
                  ? "Permission Group Name"
                  : "اسم مجموعة الصلاحيات"}
              </Label>
              <Input
                type="text"
                placeholder={
                  language === "en"
                    ? "ex: Paramedic, Supervisor, driver"
                    : "مثال: مسعف، مشرف، سائق"
                }
                value={PermissionGroupData.name}
                onChange={(e) => {
                  setPermissionGroupData({
                    ...PermissionGroupData,
                    name: e.target.value,
                  });
                }}
              />
              {formError && <div className="text-red-500">{formError}</div>}
            </div>
            {/* <Button
              onClick={() => {
                if (
                  PermissionGroupData.permissions.length ===
                  Object.values(permissionData).flat().length
                ) {
                  setPermissionGroupData({
                    ...PermissionGroupData,
                    permissions: [],
                  });
                } else {
                  setPermissionGroupData({
                    ...PermissionGroupData,
                    permissions: Object.values(permissionData)
                      .flatMap((p: any) => Object.values(p))
                      .map((p: any) => p.id),
                  });
                }
              }}
              className="my-4 mx-auto"
            >
              {language === "en" ? "Select All" : "تحديد الكل"}
            </Button> */}
            <div className="w-full lg:w-4/5 mx-auto mt-4">
              <Label>{language === "en" ? "Permissions" : "الصلاحيات"}</Label>

              {/* Permissions Table */}
              <Table>
                <TableCaption>
                  {language === "en" ? "Permissions Table" : "جدول الصلاحيات"}
                </TableCaption>
                <TableHeader
                  className="text-start"
                >
                  <TableRow>
                    <TableHead className="text-start">{language === "en" ? "Endpoint" : "نقطة النهاية"}</TableHead>
                    <TableHead className="text-start">{language === "en" ? "View" : "عرض"}</TableHead>
                    <TableHead className="text-start">{language === "en" ? "Add" : "إضافة"}</TableHead>
                    <TableHead className="text-start">{language === "en" ? "Change" : "تعديل"}</TableHead>
                    <TableHead className="text-start">{language === "en" ? "Delete" : "حذف"}</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {permissionData &&
                    Object.keys(permissionData).map((endpoint: string) => {
                      const representativePermission =
                        permissionData[endpoint].view ||
                        permissionData[endpoint].add ||
                        permissionData[endpoint].change ||
                        permissionData[endpoint].delete;

                      return (
                        <TableRow key={endpoint}>
                          {/* Display the name of the permission */}
                          <TableCell>{representativePermission?.name}</TableCell>

                          {/* View permission */}
                          <TableCell>
                            <Checkbox
                              checked={PermissionGroupData.permissions.includes(
                                permissionData[endpoint].view?.id
                              )}
                              onCheckedChange={(e) =>
                                handlePermissionChange(
                                  e,
                                  permissionData[endpoint].view
                                )
                              }
                            />
                          </TableCell>

                          {/* Add permission */}
                          <TableCell>
                            <Checkbox
                              checked={PermissionGroupData.permissions.includes(
                                permissionData[endpoint].add?.id
                              )}
                              onCheckedChange={(e) =>
                                handlePermissionChange(
                                  e,
                                  permissionData[endpoint].add
                                )
                              }
                            />
                          </TableCell>

                          {/* Change permission */}
                          <TableCell>
                            <Checkbox
                              checked={PermissionGroupData.permissions.includes(
                                permissionData[endpoint].change?.id
                              )}
                              onCheckedChange={(e) =>
                                handlePermissionChange(
                                  e,
                                  permissionData[endpoint].change
                                )
                              }
                            />
                          </TableCell>

                          {/* Delete permission */}
                          <TableCell>
                            <Checkbox
                              checked={PermissionGroupData.permissions.includes(
                                permissionData[endpoint].delete?.id
                              )}
                              onCheckedChange={(e) =>
                                handlePermissionChange(
                                  e,
                                  permissionData[endpoint].delete
                                )
                              }
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </div>
          </CardDescription>
        </CardContent>
        <CardFooter>
          <Button
            onClick={handelSubmit}
            className="my-4 w-4/5 mx-auto"
            disabled={isSubmit}
          >
            {isSubmit ? (
              <LoadingIcon />
            ) : language === "en" ? (
              "Create"
            ) : (
              "إنشاء"
            )}
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
};

export default PermissionGroupCreate;
