import ApiUrl from "./api-url";
import axios, {isCancel} from 'axios';
import {ApiHeaderOptions} from "./api-header-options";
import { users } from "./user-service";
import { useErrorStore } from "@/components/context/ErrorContext";


const createStage = async (data:any) => {
    const url = `${ApiUrl}/v1/projects/stages/`;
    return await axios.post(url, data, ApiHeaderOptions()).then((response) => {
        return response;
    }).catch((error:any) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                const { setError } = useErrorStore.getState();
                setError(error?.message + "create stage", "medium");
                console.log('Form Error', error);
                throw error;
            }
    });
}

const getStages = async () => {
    const url = `${ApiUrl}/v1/projects/stages/`;
    return await axios.get(url, ApiHeaderOptions()).then((response) => {
        return response;
    }).catch((error:any) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                const { setError } = useErrorStore.getState();
                setError(error?.message + "list stages", "high");
                console.log('Form Error', error);
                throw error;
            }
    });
}

const getStage = async (id:any) => {
    const url = `${ApiUrl}/v1/projects/stages/${id}/`;
    return await axios.get(url, ApiHeaderOptions()).then((response) => {
        return response;
    }).catch((error:any) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                const { setError } = useErrorStore.getState();
                setError(error?.message + "get stage", "high");
                console.log('Form Error', error);
                throw error;
            }
    });
}

const updateStage = async (id:any, data:any) => {
    const url = `${ApiUrl}/v1/projects/stages/${id}/`;
    return await axios.patch(url, data, ApiHeaderOptions()).then((response) => {
        return response;
    }).catch((error:any) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                const { setError } = useErrorStore.getState();
                setError(error?.message + "update stage", "medium");
                console.log('Form Error', error);
                throw error;
            }
    });
}

const getProjectManagers = async () => {
    const url = `${ApiUrl}/v1/core/users/simple/`;
    return await axios.get(url, ApiHeaderOptions()).then((response) => {
        return response;
    }).catch((error:any) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                const { setError } = useErrorStore.getState();
                setError(error?.message + "get project managers", "high");
                console.log('Form Error', error);
                throw error;
            }
    });
}

const getProjectSpportTeams = async () => {
    const url = `${ApiUrl}/v1/core/users/simple/`;
    return await axios.get(url, ApiHeaderOptions()).then((response) => {
        return response;
    }).catch((error:any) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                const { setError } = useErrorStore.getState();
                setError(error?.message + "get project support teams", "high");
                console.log('Form Error', error);
                throw error;
            }
    });
}

const getStatuses = async () => {
    const url = `${ApiUrl}/v1/projects/status/`;
    return await axios.get(url, ApiHeaderOptions()).then((response) => {
        return response;
    }).catch((error:any) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                const { setError } = useErrorStore.getState();
                setError(error?.message + "get statuses", "high");
                console.log('Form Error', error);
                throw error;
            }
    });
}

const createStatus = async (data:any) => {
    const url = `${ApiUrl}/v1/projects/status/`;
    return await axios.post(url, data, ApiHeaderOptions()).then((response) => {
        return response;
    }).catch((error:any) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                const { setError } = useErrorStore.getState();
                setError(error?.message + "create status", "medium");
                console.log('Form Error', error);
                throw error;
            }
    });
}

const getTaskStages = async () => {
    const url = `${ApiUrl}/v1/projects/task_stages/`;
    return await axios.get(url, ApiHeaderOptions()).then((response) => {
        return response;
    }).catch((error:any) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                const { setError } = useErrorStore.getState();
                setError(error?.message + "get task stages", "high");
                console.log('Form Error', error);
                throw error;
            }
    });
}

const getTaskTemplates = async () => {
    const url = `${ApiUrl}/v1/projects/task_templates/`;
    return await axios.get(url, ApiHeaderOptions()).then((response) => {
        return response;
    }).catch((error:any) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                const { setError } = useErrorStore.getState();
                setError(error?.message + "get task templates", "high");
                console.log('Form Error', error);
                throw error;
            }
    });
}

const createProjectType = async (data:any) => {
    const url = `${ApiUrl}/v1/projects/types/`;
    return await axios.post(url, data, ApiHeaderOptions()).then((response) => {
        return response;
    }).catch((error:any) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                const { setError } = useErrorStore.getState();
                setError(error?.message + "create project type", "medium");
                console.log('Form Error', error);
                throw error;
            }
    });
}

const getProjectType = async () => {
    const url = `${ApiUrl}/v1/projects/types/`;
    return await axios.get(url, ApiHeaderOptions()).then((response) => {
        return response;
    }).catch((error:any) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                const { setError } = useErrorStore.getState();
                setError(error?.message + "get project type", "high");
                console.log('Form Error', error);
                throw error;
            }
    });
}

const projects = {
    list : async () => {
        const url = `${ApiUrl}/v1/projects/projects/`;
        return await axios.get(url, ApiHeaderOptions()).then((response) => {
            return response;
        }).catch((error:any) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                const { setError } = useErrorStore.getState();
                setError(error?.message + "list projects", "high");
                console.log('Form Error', error);
                throw error;
            }
        });
    },

    create : async (data:any) => {
        const url = `${ApiUrl}/v1/projects/projects/`;
        return await axios.post(url, data, ApiHeaderOptions()).then((response) => {
            return response;
        }).catch((error:any) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                const { setError } = useErrorStore.getState();
                setError(error?.message + "create project", "medium");
                console.log('Form Error', error);
                throw error;
            }
        });
    },
    get : async (id:any) => {
        const url = `${ApiUrl}/v1/projects/projects/${id}/`;
        return await axios.get(url, ApiHeaderOptions()).then((response) => {
            return response;
        }).catch((error:any) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                const { setError } = useErrorStore.getState();
                setError(error?.message + "get project", "high");
                console.log('Form Error', error);
                throw error;
            }
        });
    },
    update : async (id:any, data:any) => {
        const url = `${ApiUrl}/v1/projects/projects/${id}/`;
        return await axios.patch(url, data, ApiHeaderOptions()).then((response) => {
            return response;
        }).catch((error:any) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                const { setError } = useErrorStore.getState();
                setError(error?.message + "update project", "medium");
                console.log('Form Error', error);
                throw error;
            }
        });
    }
}

const projectTypes = {
    list : async () => {
        const url = `${ApiUrl}/v1/projects/types/`;
        return await axios.get(url, ApiHeaderOptions()).then((response) => {
            return response;
        }).catch((error:any) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                const { setError } = useErrorStore.getState();
                setError(error?.message + "list project types", "high");
                console.log('Form Error', error);
                throw error;
            }
        });
    },

    create : async (data:any) => {
        const url = `${ApiUrl}/v1/projects/types/`;
        return await axios.post(url, data, ApiHeaderOptions()).then((response) => {
            return response;
        }).catch((error:any) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                const { setError } = useErrorStore.getState();
                setError(error?.message + "create project type", "medium");
                console.log('Form Error', error);
                throw error;
            }
        });
    },
    get : async (id:any) => {
        const url = `${ApiUrl}/v1/projects/types/${id}/`;
        return await axios.get(url, ApiHeaderOptions()).then((response) => {
            return response;
        }).catch((error:any) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                const { setError } = useErrorStore.getState();
                setError(error?.message + "get project type", "high");
                console.log('Form Error', error);
                throw error;
            }
        });
    },
    update : async (id:any, data:any) => {
        const url = `${ApiUrl}/v1/projects/types/${id}/`;
        return await axios.put(url, data, ApiHeaderOptions()).then((response) => {
            return response;
        }).catch((error:any) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                const { setError } = useErrorStore.getState();
                setError(error?.message + "update project type", "medium");
                console.log('Form Error', error);
                throw error;
            }
        });
    }
}

const getDepartments = async () => {
    const url = `${ApiUrl}/v1/core/departments/`;
    return await axios.get(url, ApiHeaderOptions()).then((response) => {
        return response;
    }).catch((error:any) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                const { setError } = useErrorStore.getState();
                setError(error?.message + "get departments", "high");
                console.log('Form Error', error);
                throw error;
            }
    });
}

const getDepartment = async (id:any) => {
    const url = `${ApiUrl}/v1/core/departments/${id}/`;
    return await axios.get(url, ApiHeaderOptions()).then((response) => {
        return response;
    }).catch((error:any) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                const { setError } = useErrorStore.getState();
                setError(error?.message + "get department", "high");
                console.log('Form Error', error);
                throw error;
            }
    });
}

const createDepartment = async (data:any) => {
    const url = `${ApiUrl}/v1/core/departments/`;
    return await axios.post(url, data, ApiHeaderOptions()).then((response) => {
        return response;
    }).catch((error:any) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                const { setError } = useErrorStore.getState();
                setError(error?.message + "create department", "medium");
                console.log('Form Error', error);
                throw error;
            }
    });
}

const updateDepartment = async (id:any, data:any) => {
    const url = `${ApiUrl}/v1/core/departments/${id}/`;
    return await axios.patch(url, data, ApiHeaderOptions()).then((response) => {
        return response;
    }).catch((error:any) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                const { setError } = useErrorStore.getState();
                setError(error?.message + "update department", "medium");
                console.log('Form Error', error);
                throw error;
            }
    });
}
const comments = {
    list : async () => {
        const url = `${ApiUrl}/v1/projects/comments/`;
        return await axios.get(url, ApiHeaderOptions()).then((response) => {
            return response;
        }).catch((error:any) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                const { setError } = useErrorStore.getState();
                setError(error?.message + "list comments", "high");
                console.log('Form Error', error);
                throw error;
            }
        });
    },

    create : async (data:any) => {

        const url = `${ApiUrl}/v1/projects/comments/`;
        return await axios.post(url, data, ApiHeaderOptions(undefined)).then((response) => {
            return response;
        }).catch((error:any) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                const { setError } = useErrorStore.getState();
                setError(error?.message + "create comment", "medium");
                console.log('Form Error', error);
                throw error;
            }
        });
    },
    get : async (id:any) => {
        const url = `${ApiUrl}/v1/projects/comments/${id}/`;
        return await axios.get(url, ApiHeaderOptions()).then((response) => {
            return response;
        }).catch((error:any) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                const { setError } = useErrorStore.getState();
                setError(error?.message + "get comment", "high");
                console.log('Form Error', error);
                throw error;
            }
        });
    },
    update : async (id:any, data:any) => {
        const url = `${ApiUrl}/v1/projects/comments/${id}/`;
        return await axios.put(url, data, ApiHeaderOptions()).then((response) => {
            return response;
        }).catch((error:any) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                const { setError } = useErrorStore.getState();
                setError(error?.message + "update comment", "medium");
                console.log('Form Error', error);
                throw error;
            }
        });
    }
}

export { 
     createStage,
     createStatus,
     getStages,
     getStatuses,
     getProjectManagers,
     getProjectSpportTeams,
     getTaskStages,
     getTaskTemplates,
     createProjectType,
     getProjectType,
     getDepartments,
     createDepartment,
     getDepartment,
     getStage,
     updateStage,
     updateDepartment,
     projectTypes,
     projects,
     comments
    };