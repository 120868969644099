import { create } from 'zustand';

export const useErrorStore = create((set:any) => ({
  message: "",
  priority: "",
  setError: (message:any, priority:any) => set({ message, priority }),
  clearError: () => set({ message: "", priority: "" }),
}));


