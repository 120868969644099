import ApiUrl from "./api-url";
import axios, { isCancel } from 'axios';
import { ApiHeaderOptions } from "./api-header-options";
import { users } from "./user-service";
import { list } from "postcss";
import { useErrorStore } from "@/components/context/ErrorContext";


const EmailTemplates = {
    create: async (data: any) => {
        const url = `${ApiUrl}/v1/notifications/email_templates/`;
        return axios.post(url, data, ApiHeaderOptions()).then((response) => {
            return response.data;
        }).catch((error) => {
            const { setError } = useErrorStore.getState();
            setError(error?.message + "email template", "medium");
            throw error;
        });
    },
    list: async () => {
        const url = `${ApiUrl}/v1/notifications/email_templates/`;
        return axios.get(url, ApiHeaderOptions()).then((response) => {
            return response.data;
        }).catch((error) => {
            const { setError } = useErrorStore.getState();
            setError(error?.message + "email template", "high");
            throw error;
        });
    },
    get: async (id: any) => {
        const url = `${ApiUrl}/v1/notifications/email_templates/${id}/`;
        return axios.get(url, ApiHeaderOptions()).then((response) => {
            return response.data;
        }).catch((error) => {
            const { setError } = useErrorStore.getState();
            setError(error?.message + "email template", "high");
            throw error;
        });
    },
    update: async (id: any, data: any) => {
        const url = `${ApiUrl}/v1/notifications/email_templates/${id}/`;
        return axios.patch(url, data, ApiHeaderOptions()).then((response) => {
            return response.data;
        }).catch((error) => {
            const { setError } = useErrorStore.getState();
            setError(error?.message + "email template", "medium");
            throw error;
        });
    },
}

const SystemNotifications = {
    list: async () => {
        const url = `${ApiUrl}/v1/notifications/system_notifications/`;
        return axios.get(url, ApiHeaderOptions()).then((response) => {
            return response.data;
        }).catch((error) => {
            const { setError } = useErrorStore.getState();
            setError(error?.message + "system notification", "high");
            console.log("error from service", error);
            throw error;
        });
    },
    get: async (id: any) => {
        const url = `${ApiUrl}/v1/notifications/system_notifications/${id}/`;
        return axios.get(url, ApiHeaderOptions()).then((response) => {
            return response.data;
        }).catch((error) => {
            const { setError } = useErrorStore.getState();
            setError(error?.message + "system notification", "high");
            throw error;
        });
    },
    create: async (data: any) => {
        const url = `${ApiUrl}/v1/notifications/system_notifications/`;
        return axios.post(url, data, ApiHeaderOptions()).then((response) => {
            return response.data;
        }).catch((error) => {
            const { setError } = useErrorStore.getState();
            setError(error?.message + "system notification", "medium");
            throw error;
        });
    },
    options: async () => {
        const url = `${ApiUrl}/v1/notifications/system_notifications/`;
        return axios.options(url, ApiHeaderOptions()).then((response) => {
            return response.data;
        }).catch((error) => {   
            const { setError } = useErrorStore.getState();
            setError(error?.message + "system notification", "high");
            throw error;
        });
    },
    triggers: async () => {
        const url = `${ApiUrl}/v1/notifications/system_notifications/`;
        return axios.options(url, ApiHeaderOptions()).then((response) => {
            console.log("response", response?.data?.actions?.POST?.trigger?.choices);
            return response?.data?.actions?.POST?.trigger?.choices;
        }).catch((error) => {
            const { setError } = useErrorStore.getState();
            setError(error?.message + "system notification", "high");
            throw error;
        });
    },
    update: async (id: any, data: any) => {
        const url = `${ApiUrl}/v1/notifications/system_notifications/${id}/`;
        return axios.patch(url, data, ApiHeaderOptions()).then((response) => {
            return response.data;
        }).catch((error) => {
            const { setError } = useErrorStore.getState();
            setError(error?.message + "system notification", "medium");
            throw error;
        });
    },
}


export { EmailTemplates, SystemNotifications };