import ApiUrl from "./api-url";
import axios, {isCancel} from 'axios';
import {ApiHeaderOptions} from "./api-header-options";
import { users } from "./user-service";
import { useErrorStore } from "@/components/context/ErrorContext";


const getMyTasks = async () => {
    const url = `${ApiUrl}/v1/projects/tasks/me/`;
    return await axios.get(url, ApiHeaderOptions()).then((response) => {
        return response;
    }).catch((error:any) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                const { setError } = useErrorStore.getState();
                setError(error?.message + "get my tasks", "high");
                console.log('Form Error', error);
                throw error;
            }
            
    });
}

const getTask = async (id:any) => {
    const url = `${ApiUrl}/v1/projects/tasks/${id}/`;
    return await axios.get(url, ApiHeaderOptions()).then((response) => {
        return response;
    }).catch((error:any) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                const { setError } = useErrorStore.getState();
                setError(error?.message + "get task", "high");
                console.log('Form Error', error);
                throw error;
            }
    });
}

const getTaskOptions = async (id:any) => {
    const url = `${ApiUrl}/v1/projects/tasks/${id}/`;
    return await axios.options(url, ApiHeaderOptions()).then((response) => {
        return response;
    }).catch((error:any) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                const { setError } = useErrorStore.getState();
                setError(error?.message + "get task options", "high");
                console.log('Form Error', error);
                throw error;
            }
    });
}

const editTask = async (id:any, data:any) => {
    const url = `${ApiUrl}/v1/projects/tasks/${id}/`;
    return await axios.patch(url, data, ApiHeaderOptions()).then((response) => {
        return response;
    }).catch((error:any) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                const { setError } = useErrorStore.getState();
                setError(error?.message + "edit task", "medium");
                console.log('Form Error', error);
                throw error;
            }
    });
}

export {getMyTasks, getTask, editTask, getTaskOptions};

