import { useLanguage } from "@/components/context/LanguageContext";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import LoadingIcon from "@/components/loading/loading-icon";
import { createDepartment } from "@/services/project-service";

const CreateDepartment = () => {
  const navigate = useNavigate();
  const { language } = useLanguage();
  const [department, setDepartment] = useState<any>({
    title: "",
    description: "",
  });
  const [formLoading, setFormLoading] = useState(false);
  const [formError, setFormError] = useState("");

  useEffect(() => {
    document.title ="Lazem GO - Create Department";
  } , []);

  const handleCreateDepartment = async () => {
    setFormLoading(true);
    setFormError("");
    if (checkForm()) {
      await createDepartment(department)
        .then((response: any) => {
          // console.log(response);
          navigate("/projects");
        })
        .catch((error) => {
        })
        .finally(() => {
          setFormLoading(false);
        });
    }
  };

  const checkForm = () => {
    if (department.title === "") {
      setFormError(language === "en" ? "Title is required" : "العنوان مطلوب");
      return false;
    }
    return true;
  };

  return (
    <div
      className="flex flex-col items-center justify-center h-full"
      dir={language === "en" ? "ltr" : "rtl"}
    >

      {
        <Card className="w-full md:w-1/2">
          <CardHeader>
            <CardTitle>
              {language === "en" ? "Create Department" : "إنشاء قسم"}
            </CardTitle>
          </CardHeader>
          <CardContent className="text-start">
            <div className="">
              <div className="mb-4">
                <Label className="mb-4">
                  {language === "en" ? "Title" : "العنوان"}
                </Label>
                <Input
                  value={department.title}
                  onChange={(e) => {
                    setDepartment({
                      ...department,
                      title: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="mb-4">
                <Label className="mb-4">
                  {language === "en" ? "Description" : "الوصف"}
                </Label>
                <Input
                  value={department.description}
                  onChange={(e) => {
                    setDepartment({
                      ...department,
                      description: e.target.value,
                    });
                  }}
                />
              </div>
              {formError !== "" ? (
                <div className="mb-4 text-red-500">{formError}</div>
              ) : null}
              <div className="flex justify-end">
                <Button
                  onClick={handleCreateDepartment}
                  disabled={formLoading}
                  className="w-full "
                >
                  {formLoading ? (
                    <>
                      <LoadingIcon />
                    </>
                  ) : language === "en" ? (
                    "Create Department"
                  ) : (
                    "إنشاء قسم"
                  )}
                </Button>
              </div>
            </div>
          </CardContent>
        </Card>
      }
    </div>
  );
};

export default CreateDepartment;
