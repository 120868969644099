import EmailEditor, { EditorRef, EmailEditorProps } from "react-email-editor";
import { useRef, useState } from "react";
import { useLanguage } from "@/components/context/LanguageContext";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardFooter,
  CardDescription,
} from "@/components/ui/card";
import { useTheme } from "@/components/theme-provider";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { EmailTemplates } from "@/services/notification-services";
import { useQuery } from "@tanstack/react-query";
import LoadingScreen from "@/components/loading/loading-screen";
import LoadingIcon from "@/components/loading/loading-icon";
import { Textarea } from "@/components/ui/textarea";

const EmailManage = () => {
  const { language } = useLanguage();
  const { id } = useParams<any>();
  const navigate = useNavigate();
  const emailEditorRef = useRef<EditorRef | null>(null);
  const [preview, setPreview] = useState(false);
  const { theme } = useTheme();
  const [subject, setSubject] = useState("");
  const [title, setTitle] = useState("");
  const [formError, setFormError] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);

  const {
    data: emailTemplate,
    isLoading: emailTemplateLoading,
  } = useQuery({
    queryKey: ["emailTemplate", id],
    queryFn: () => EmailTemplates.get(id).then((response) => {
      setSubject(response.subject);
      setTitle(response.title);
      setDescription(response.description);
      return response;
    }),
    refetchOnWindowFocus: false,
  });

  const exportHtml = () => {
    console.log("subject", subject);
    if (!checkInputs()) {
      return;
    }
    setLoading(true);
    console.log("exportHtml");
    setFormError("");
    const unlayer = emailEditorRef.current?.editor;
    unlayer?.exportHtml((data) => {
      const { html, design } = data;
      let template_data = {
        title: title,
        subject: subject,
        body: html,
        body_json: design,
        description: description,
      }
      EmailTemplates.update(id, template_data).then((response) => {
        setLoading(false);
        navigate("/system-notification/emails");
      }).catch((error) => {
        setLoading(false);
        console.log("error", error);
        setFormError(language === "en" ? "Failed to save email template" : "فشل في حفظ قالب البريد الإلكتروني");
      }
      );

    });
  };

  const checkInputs = () => {
    if (!subject) {
      setFormError(language === "en" ? "Please enter the email subject" : "الرجاء إدخال موضوع البريد الإلكتروني");
      return false;
    }
    else if (!title) {
      setFormError(language === "en" ? "Please enter the email template title" : "الرجاء إدخال عنوان قالب البريد الإلكتروني");
      return false;
    }
    return true;
  };

  const togglePreview = () => {
    const unlayer = emailEditorRef.current?.editor;

    if (preview) {
      unlayer?.hidePreview();
      setPreview(false);
    } else {
      unlayer?.showPreview({ device: "desktop" });
      setPreview(true);
    }
  };

  const onReady: EmailEditorProps["onReady"] = (unlayer) => {
    if (emailTemplate && emailTemplate.body) {
      unlayer.loadDesign(emailTemplate.body_json);
    }
  };
  

  return (
    <div
      dir={language === "en" ? "ltr" : "rtl"}
    >
    {emailTemplateLoading && <LoadingScreen />}
      <div>
        <Card>
          <CardHeader>
            <CardTitle>
              {language === "en"
                ? "Email Builder"
                : "برنامج بناء البريد الإلكتروني"}
            </CardTitle>
          </CardHeader>
          <CardContent>
            <CardDescription>
              {formError &&
                <p className="text-red-500 text-sm">{formError}</p>
              }
            </CardDescription>
          </CardContent>
          <CardFooter className="flex flex-col justify-center space-x-4">
            <div
            >
              {preview && (
                <Button
                  onClick={exportHtml}
                  className="mx-6"
                  disabled={loading}
                >
                    {loading ? <LoadingIcon /> : language === "en" ? "Save" : "حفظ"}

                </Button>
              )}

              <Button
                onClick={togglePreview}
                variant={preview ? "destructive" : "default"}
                className="mx-6"
                disabled={loading}
              >
                {preview
                  ? language === "en"
                    ? "Build view"
                    : "عرض البناء"
                  : language === "en"
                    ? "Preview"
                    : "معاينة"}
              </Button>
            </div>
            <div
              className="flex flex-col items-start w-full"
            >
              <Label
                className="mt-4 w-full text-start"
              >
                {language === "en" ? "Email subject" : "موضوع البريد الإلكتروني"}
              </Label>
              <Input
                className="w-full my-2 text-start"
                defaultValue={emailTemplate?.subject}
                onChange={(e) => {
                  setSubject(e.target.value);
                }}
              />
            </div>
            <div
              className="flex flex-col items-start w-full"
            >
              <Label
                className="mt-4 w-full text-start"
              >
                {language === "en" ? "Email template title" : "اسم القالب"}
              </Label>
              <Input
                className="w-full my-2 text-start"
                defaultValue={emailTemplate?.title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              />
            </div>
            <div
              className="flex flex-col items-start w-full"
            >
              <Label
                className="mt-4 w-full text-start"
              >
                {language === "en" ? "Email template description" : "شرح القالب"}
              </Label>
              <Textarea
                className="w-full my-2 text-start"
                defaultValue={emailTemplate?.description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              />
            </div>
          </CardFooter>
        
                {
                    emailTemplate &&
                    <EmailEditor
                        ref={emailEditorRef}
                        onReady={onReady}
                        onLoad={() => {
                                const unlayer = emailEditorRef.current?.editor;
                                unlayer?.loadDesign(emailTemplate.body);
                        }}
                        options={{
                        version: "latest",
                        appearance: {
                            theme: theme === "dark" ? "dark" : "light",
                        },
                        }}
                    />
                }
        </Card>

      </div>
    </div>
  );
};

export default EmailManage;
