import { useLanguage } from "@/components/context/LanguageContext";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import LoadingScreen from "@/components/loading/loading-screen";
import { Label } from "@/components/ui/label";
import { format, toZonedTime } from "date-fns-tz";

const ItTaskDashboard = () => {
  const { language } = useLanguage();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [tasks, setTasks] = useState<any>([
    {
      id: 1,
      title: "Task 1",
      description: "Task 1 Description",
      created_at: "2021-10-10T10:10:10Z",
      status: "pending",
    },
    {
      id: 2,
      title: "Task 2",
      description: "Task 2 Description",
      created_at: "2021-10-10T10:10:10Z",
      status: "completed",
    },
    {
      id: 3,
      title: "Task 3",
      description: "Task 3 Description",
      created_at: "2021-10-10T10:10:10Z",
      status: "pending",
    },
  ]);

  useEffect(() => {
    document.title = "Lazem GO - IT Tasks";
  }, []);

  function formatDate(isoDate: any) {
    const timeZone =
      Intl.DateTimeFormat().resolvedOptions().timeZone || "Asia/Riyadh";
    let formattedDateTime = format(
      toZonedTime(isoDate, timeZone),
      "MMMM d, yyyy hh:mm a",
      { timeZone }
    );
    return formattedDateTime;
  }

  return (
    <div dir={language === "en" ? "ltr" : "rtl"}>
      <div className="my-4">
        <h1 className="text-2xl font-bold">
          {language === "en" ? "IT Tasks" : "مهام تقنية المعلومات"}
        </h1>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {tasks.map((task: any) => (
          <Card
            key={task.id}
            className="cursor-pointer"
            onClick={() => navigate(`/it-tasks/id/${task.id}`)}
          >
            <CardHeader>
              <Label
                className={`
                    text-start w-full
                  ${task.status === "completed"
                    ? "text-green-500"
                    : "text-red-500"}
                `}
              >
                {task.status}
              </Label>
              <CardTitle>{task.title}</CardTitle>
            </CardHeader>
            <CardContent>
              <CardDescription>
                <div className="my-2">{task.description}</div>
                <div className="my-2">{formatDate(task.created_at)}</div>
              </CardDescription>
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default ItTaskDashboard;
