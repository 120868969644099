import { useErrorStore } from "../context/ErrorContext";
import HighLevelError from "./high-level-error";
import LowLevelError from "./low-level-error";
import MedLevelError from "./med-level-error";

const ErrorManager = () => {
  const { message, priority, clearError } = useErrorStore();

  if (message === "") {
    return null;
  }

  if (priority === "high") {
    return <HighLevelError error={message} onClose={clearError} />;
  } else if (priority === "medium") {
    return <MedLevelError error={message} onClose={clearError} />;
  } else {
    return <LowLevelError error={message} onClose={clearError} />;
  }
};

export default ErrorManager;
