import { useLanguage } from "@/components/context/LanguageContext";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import LoadingScreen from "@/components/loading/loading-screen";
import { Label } from "@/components/ui/label";
import { useQuery } from "@tanstack/react-query";
import { formTemplates } from "@/services/forms-service";
import { Button } from "@/components/ui/button";

const FormDashboard = () => {
  const { language } = useLanguage();
  const navigate = useNavigate();
  const [forms, setForms] = useState<any>();

  useEffect(() => {
    document.title = "Lazem GO - Forms";
  } , []);

  const fetchForms = async () => {
    if (!forms) {
      await formTemplates
        .list()
        .then((response) => {
          // console.log('Forms', response.data);
          setForms(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const { isLoading: formsLoading } = useQuery({
    queryKey: ["forms"],
    queryFn: fetchForms,
    refetchOnWindowFocus: false,
  });

  return (
    <div dir={language === "en" ? "ltr" : "rtl"}>
      {formsLoading === true && <LoadingScreen />}
      {
        <>
          {forms && (
            <>
              <div className="my-4">
                <h1 className="text-2xl font-bold">
                  {language === "en" ? "Forms" : "النماذج"}
                </h1>
                <Button
                  onClick={() => navigate("/forms/create")}
                  className="my-4 flex mx-6"
                >
                  {language === "en" ? "Create Form" : "إنشاء نموذج"}
                </Button>
              </div>
              <div className="flex flex-wrap justify-center items-center gap-4">
                {forms.length > 0 ? (
                  <>
                    {forms.map((form: any) => (
                      <Card
                        key={form.id}
                        className="m-3 w-64 h-64 m-auto cursor-pointer cursor-pointer hover:shadow-lg"
                        onClick={() => navigate(`/forms/id/${form.id}`)}
                      >
                        <CardHeader>
                          <CardTitle className="text-center font-bold text-xl">
                            {form.title}
                          </CardTitle>
                        </CardHeader>
                        <CardContent className="text-start">
                          <CardDescription className="text-start flex flex-col gap-4 justify-center items-center">
                            <Label>{form.description}</Label>
                          </CardDescription>
                        </CardContent>
                      </Card>
                    ))}
                  </>
                ) : (
                  <div className="flex justify-center items-center">
                    <p>
                      {language === "en" ? "No forms found" : "لا توجد نماذج"}
                    </p>
                  </div>
                )}
              </div>
            </>
          )}
        </>
      }
    </div>
  );
};

export default FormDashboard;
