import { useLanguage } from "@/components/context/LanguageContext";
import { Button } from "@/components/ui/button";
import { 
    Card,
    CardContent,
    CardHeader,
    CardTitle,
} from "@/components/ui/card";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { EmailTemplates } from "@/services/notification-services";
import LoadingScreen from "@/components/loading/loading-screen";

const EmailDashboard = () => {
    const navigate = useNavigate();
    const { language } = useLanguage();

    const{
        data: emailTemplatesData,
        isLoading: emailTemplatesLoading,
    } = useQuery({
        queryKey: ['emailTemplates'],
        queryFn: () => EmailTemplates.list(),
        refetchOnWindowFocus: false,
    });
    

    return (
        <div
            dir={language === 'en' ? 'ltr' : 'rtl'}
        >

            {emailTemplatesLoading && <LoadingScreen />}
            <div
                className="flex justify-content-center mt-10 mx-10"
            >
                <Button
                    onClick={() => navigate('/system-notification/emails/builder')}
                    className=""
                >
                    {language === 'en' ? 'Create New Email Template' : 'إنشاء قالب بريد إلكتروني جديد'}
                </Button>
            </div>
            <div>
                <h1
                    className="text-3xl font-bold text-center mt-10"
                >
                    {language === 'en' ? 'Email Templates' : 'قوالب البريد الإلكتروني'}
                </h1>
            </div>
            <div
                className="flex justify-content-start flex-wrap mx-auto mt-10 gap-6 p-4"
            >
                {
                    emailTemplatesData && emailTemplatesData.map((emailTemplate:any) => (
                        <div key={emailTemplate.id} className="m-auto lg:m-0">
                            <Card
                                className="m-3 w-64 h-64 mx-auto lg:mx-0 flex flex-col justify-content-center cursor-pointer transition duration-300 ease-in-out transform hover:scale-105"
                                onClick={() => navigate('/system-notification/emails/id/' + emailTemplate.id)}
                            >
                                <CardHeader className="w-full flex justify-content-center m-auto">
                                    <CardTitle className="text-2xl font-bold flex justify-content-center m-auto">
                                        {emailTemplate.title}
                                    </CardTitle>
                                    <CardContent>
                                            {emailTemplate.description}
                                    </CardContent>
                                </CardHeader>
                            </Card>
                        </div>
                    ))
                }
            </div>
        </div>
    );
};

export default EmailDashboard;