import Cookies from 'js-cookie';

const getAuthorizationToken = () => {
  return Cookies.get('Authorization') || '';
};
const ApiHeaderOptions = (content_type?:"application/json") => {
  return {
    headers: {
      'Content-Type': content_type,
      'Authorization': getAuthorizationToken(),
    }
  }
}
export  {ApiHeaderOptions, getAuthorizationToken};