import EmailEditor, { EditorRef, EmailEditorProps } from "react-email-editor";
import { useRef, useState } from "react";
import { useLanguage } from "@/components/context/LanguageContext";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardFooter,
  CardDescription,
} from "@/components/ui/card";
import { useTheme } from "@/components/theme-provider";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { EmailTemplates } from "@/services/notification-services";
import { Textarea } from "@/components/ui/textarea";

const EmailBuilder = () => {
  const { theme } = useTheme();
  const { language } = useLanguage();
  const navigate = useNavigate();
  const emailEditorRef = useRef<EditorRef | null>(null);
  const [preview, setPreview] = useState(false);
  const [subject, setSubject] = useState("");
  const [title, setTitle] = useState("");
  const [formError, setFormError] = useState("");
  const [description, setDescription] = useState("");


  const exportHtml = () => {
    console.log("subject", subject);
    if (!checkInputs()) {
      return;
    }
    console.log("exportHtml");
    setFormError("");
    const unlayer = emailEditorRef.current?.editor;
    unlayer?.exportHtml((data) => {
      const { html, design } = data;
      let template_data = {
        title: title,
        subject: subject,
        body: html,
        body_json: design,
        description: description,
      }
      EmailTemplates.create(template_data).then(() => {
        navigate("/system-notification/emails");
      }).catch((error) => {
        console.log("error", error);
        setFormError(language === "en" ? "Failed to save email template" : "فشل في حفظ قالب البريد الإلكتروني");
      }
      );

    });
  };

  const checkInputs = () => {
    if (!subject) {
      setFormError(language === "en" ? "Please enter the email subject" : "الرجاء إدخال موضوع البريد الإلكتروني");
      return false;
    }
    else if (!title) {
      setFormError(language === "en" ? "Please enter the email template title" : "الرجاء إدخال عنوان قالب البريد الإلكتروني");
      return false;
    }
    return true;
  };

  const togglePreview = () => {
    const unlayer = emailEditorRef.current?.editor;

    if (preview) {
      unlayer?.hidePreview();
      setPreview(false);
    } else {
      unlayer?.showPreview({ device: "desktop" });
      setPreview(true);
    }
  };

  const onReady: EmailEditorProps["onReady"] = (unlayer) => {
    console.log("onReady", unlayer);
  };

  return (
    <div
      dir={language === "en" ? "ltr" : "rtl"}
    >
      <div>
        <Card>
          <CardHeader>
            <div>
              <Button
                onClick={() => navigate('/system-notification/emails')}
                className="w-full my-4"
                variant={"secondary"}
              >
                {language === 'en' ? 'Back' : 'عودة'}
              </Button>

            </div>
            <CardTitle>
              {language === "en"
                ? "Email Builder"
                : "برنامج بناء البريد الإلكتروني"}
            </CardTitle>
          </CardHeader>
          <CardContent>
            <CardDescription>
              {formError &&
                <p className="text-red-500 text-sm">{formError}</p>
              }
            </CardDescription>
          </CardContent>
          <CardFooter className="flex flex-col justify-center space-x-4">
            <div
            >
              {preview && (
                <Button
                  onClick={exportHtml}
                  className="mx-6"
                >
                  {language === "en" ? "Save" : "حفظ"}
                </Button>
              )}

              <Button
                onClick={togglePreview}
                variant={preview ? "destructive" : "default"}
                className="mx-6"
              >
                {preview
                  ? language === "en"
                    ? "Build view"
                    : "عرض البناء"
                  : language === "en"
                    ? "Preview"
                    : "معاينة"}
              </Button>
            </div>
            <div
              className="flex flex-col items-start w-full"
            >
              <Label
                className="mt-4 w-full text-start"
              >
                {language === "en" ? "Email subject" : "موضوع البريد الإلكتروني"}
              </Label>
              <Input
                className="w-full my-2 text-start"
                onChange={(e) => {
                  setSubject(e.target.value);
                }}
              />
            </div>
            <div
              className="flex flex-col items-start w-full"
            >
              <Label
                className="mt-4 w-full text-start"
              >
                {language === "en" ? "Email template title" : "اسم القالب"}
              </Label>
              <Input
                className="w-full my-2 text-start"
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              />
            </div>
            <div
              className="flex flex-col items-start w-full"
            >
              <Label
                className="mt-4 w-full text-start"
              >
                {language === "en" ? "Email template description" : "شرح القالب"}
              </Label>
              <Textarea
                className="w-full my-2 text-start"
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              />
            </div>
          </CardFooter>

          <EmailEditor
            ref={emailEditorRef}
            onReady={onReady}
            options={{
              version: "latest",
              appearance: {
                theme: theme === "dark" ? "dark" : "light",
              },
            }}
          />
        </Card>

      </div>
    </div>
  );
};

export default EmailBuilder;
