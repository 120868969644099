import { useState } from "react";
import { Textarea } from "@/components/ui/textarea";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { RadioGroupItem, RadioGroup } from "@/components/ui/radio-group";
import LoadingIcon from "@/components/loading/loading-icon";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "@/components/context/LanguageContext";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardFooter,
  CardDescription,
} from "@/components/ui/card";
import { getDepartments } from "@/services/project-service";
import { useQuery } from "@tanstack/react-query";
import LoadingScreen from "@/components/loading/loading-screen";

const SupportOnboardingForm = () => {
  const { language } = useLanguage();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [onboardingForm, setOnboardingForm] = useState<any>({
    english_first_name: "",
    english_last_name: "",
    english_middle_name: "",
    arabic_first_name: "",
    arabic_last_name: "",
    arabic_middle_name: "",
    phone: "",
    gender: "",
    it_services: [],
    it_note: "",
    general_note: "",
    department: "",
  });

  const [itServices, setItServices] = useState([
    {
      title_en: "Microsoft 365 (Word, Excel, PowerPoint ...)",
      title_ar: "مايكروسوفت 365 (Word, Excel, PowerPoint ...)",
      value: "microsoft_365",
    },
    {
      title_en: "Mouse + Keyboard",
      title_ar: "فأرة + لوحة مفاتيح",
      value: "mouse_keyboard",
    },
    {
      title_en: "Monitor",
      title_ar: "شاشة",
      value: "monitor",
    },
    {
      title_en: "Laptop",
      title_ar: "لابتوب",
      value: "laptop",
    },
    {
      title_en: "Desktop",
      title_ar: "كمبيوتر مكتبي",
      value: "desktop",
    },
  ]);

  const { data: departments, isLoading: departmentsLoading } = useQuery({
    queryKey: ["departments"],
    queryFn: getDepartments,
    refetchOnWindowFocus: false,
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setOnboardingForm({
      ...onboardingForm,
      [name]: value,
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    try {
      // Add your API call here
      console.log(onboardingForm);
      navigate("/it-support/success");
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const setInputFieldDev = (name: string, placeholder: string, is_required:boolean) => {
    return (
      <div className="flex flex-col items-center justify-center gap-4 w-11/12 my-6">
        <Label className="w-full ">
            {placeholder} {is_required && <span className="text-red-500"> *</span>}
        </Label>
        <Input
            className="mt-[-8px]"
          name={name}
          value={onboardingForm[name]}
          onChange={handleChange}
        />
      </div>
    );
  }

  return (
    <div
      className="flex flex-col items-center justify-center"
      dir={language === "en" ? "ltr" : "rtl"}
    >
      {departmentsLoading && <LoadingScreen />}
      {(
        <Card className="flex flex-col items-center justify-center gap-4 w-full my-4 p-4 lg:w-1/2">
          <CardHeader>
            <CardTitle>
              {language === "en" ? "Onboarding Form" : "نموذج ابتداء العمل"}
            </CardTitle>
          </CardHeader>
          <CardContent className="text-start w-full">
            <CardDescription
                className="text-center"
            >
              {language === "en"
                ? "Please fill out the form below to complete the onboarding process."
                : "يرجى ملء النموذج أدناه لإكمال عملية البدء في العمل."}
            </CardDescription>

            {setInputFieldDev("arabic_first_name", language === "en" ? "First Name (English)" : "الاسم الأول (الإنجليزية)", true)}
            {setInputFieldDev("arabic_middle_name", language === "en" ? "Middle Name (English)" : "الاسم الأوسط (الإنجليزية)", false)}
            {setInputFieldDev("arabic_last_name", language === "en" ? "Last Name (English)" : "الاسم الأخير (الإنجليزية)", true)}
            {setInputFieldDev("english_first_name", language === "en" ? "First Name (Arabic)" : "الاسم الأول (العربية)", true)}
            {setInputFieldDev("english_middle_name", language === "en" ? "Middle Name (Arabic)" : "الاسم الأوسط (العربية)", false)}
            {setInputFieldDev("english_last_name", language === "en" ? "Last Name (Arabic)" : "الاسم الأخير (العربية)", true)}
            {setInputFieldDev("phone", language === "en" ? "Phone" : "الهاتف", true)}
            

            <div className="flex flex-col items-center justify-center gap-4 w-11/12 my-4">
              <Label className="w-full">
                {language === "en" ? "Department" : "القسم"}
              </Label>
              <Select
                name="department"
                onValueChange={(value) =>
                    setOnboardingForm({ ...onboardingForm, department: value })
                }
              >
                <SelectTrigger>
                  <SelectValue placeholder={language === "en" ? "Select Department" : "اختر القسم"} />
                </SelectTrigger>
                <SelectContent>
                  {departments?.data?.map((department: any, index: number) => (
                    <SelectGroup key={department.id}>
                      <SelectItem value={department.id}>
                        {department.title}
                      </SelectItem>
                    </SelectGroup>
                  ))}
                </SelectContent>
              </Select>
            </div>

            <div className="flex flex-col items-center justify-center gap-4 w-11/12 my-4">
              <Label className="w-full">
                {language === "en" ? "Gender" : "الجنس"}
              </Label>
              <RadioGroup className="w-full">
                <div
                  className={`flex space-x-2 ${
                    language === "en" ? "flex-row" : "flex-row-reverse"
                  }`}
                >
                  <RadioGroupItem value={"male"} id={"male"} className="mx-2" />
                  <Label htmlFor={"male"}>
                    {language === "en" ? "Male" : "ذكر"}
                  </Label>
                </div>
                <div
                  className={`flex space-x-2 ${
                    language === "en" ? "flex-row" : "flex-row-reverse"
                  }`}
                >
                  <RadioGroupItem value="female" id="female" className="mx-2" />
                  <Label htmlFor={"female"}>
                    {language === "en" ? "Female" : "أنثى"}
                  </Label>
                </div>
              </RadioGroup>
            </div>

            <div className="flex flex-col items-center justify-center gap-4 w-11/12 mt-8">
              <Label className="w-full">
                {language === "en" ? "IT Services" : "خدمات تقنية المعلومات"} (
                {language === "en"
                  ? "these services would cost extra charges for the company"
                  : "هذه الخدمات ستكلف الشركة رسوم إضافية"}
                )
              </Label>
              {itServices.map((service, index) => (
                <div key={index} className="flex items-center gap-2 w-full">
                  <Checkbox 
                    onCheckedChange={(value) => {
                        if (value) {
                            setOnboardingForm({
                                ...onboardingForm,
                                it_services: [...onboardingForm.it_services, service.value],
                            });
                        }
                        else {
                            setOnboardingForm({
                                ...onboardingForm,
                                it_services: onboardingForm.it_services.filter((item: string) => item !== service.value),
                            });
                        }
                    }}

                    checked={onboardingForm.it_services.includes(service.value)} 
                    className="mx-2 my-2" 
                    />
                  <Label>
                    {language === "en" ? service.title_en : service.title_ar}
                  </Label>
                </div>
              ))}
            </div>

            <div className="flex flex-col items-center justify-center gap-4 w-11/12 my-8">
              <Label className="w-full">
                {language === "en" ? "IT Note" : "ملاحظة تقنية المعلومات"}
              </Label>
              <Textarea
                name="it_note"
                value={onboardingForm.it_note}
                placeholder={
                  language === "en" ? "IT Note" : "ملاحظة تقنية المعلومات"
                }
                onChange={handleChange}
              />
            </div>

            <div className="flex flex-col items-center justify-center gap-4 w-11/12 my-4">
              <Label className="w-full">
                {language === "en" ? "General Note" : "ملاحظة عامة"}
              </Label>
              <Textarea
                name="general_note"
                value={onboardingForm.general_note}
                placeholder={language === "en" ? "General Note" : "ملاحظة عامة"}
                onChange={handleChange}
              />
            </div>
          </CardContent>

          <Button>
                {loading ? <LoadingIcon /> : language === "en" ? "Submit" : "إرسال"}
            </Button>
        </Card>
      )}
    </div>
  );
};

export default SupportOnboardingForm;
