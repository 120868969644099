import React, { createContext, useMemo, useContext, ReactNode } from 'react';
import Cookies from 'js-cookie';
import { useQuery } from '@tanstack/react-query';
import { users } from '@/services/user-service';

// Define the context type
interface AuthContextType {
  isAuthenticated: boolean;
  logout: () => Promise<void>;
  checkIsAuthenticated: () => boolean;
  checkPermission: (permission: string) => boolean;
  isPermissionsLoading: boolean;
}

// Create the context with a default value
const AuthContext = createContext<AuthContextType>({
  isAuthenticated: false,
  logout: () => Promise.resolve(),
  checkIsAuthenticated: () => false,
  checkPermission: () => false,
  isPermissionsLoading: false,
});

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  // Fetch permission data and authentication state
  const fetchPermissions = async () => {
    if (sessionStorage.getItem('permissions')) {
      let permissions = sessionStorage.getItem('permissions');
      return JSON.parse(permissions || '[]');
    }
    // return await refetchPermissions();
    return users.me().then((res) => {
      sessionStorage.setItem('permissions', JSON.stringify(res?.data?.group_permissions || '[]'));
      return res.data?.group_permissions
    });
  }

  const { 
    data: permissionData,
    isLoading: isPermissionsLoading,
  } = useQuery({
    queryKey: ['me'],
    queryFn: fetchPermissions,
    enabled: !!Cookies.get('Authorization'),
    refetchOnWindowFocus: false,
  });


  // UseMemo to calculate if the user is authenticated
  const isAuthenticated = useMemo(() => {
    const token = Cookies.get('Authorization');
    return !!token; // Return true if token exists, false otherwise
  }, []); // No dependencies, will only compute once

  // Check permission with useMemo to avoid recalculating unless permissionData changes
  const checkPermission = useMemo(
    () => (permission: string) => {
      if (permissionData) {
        return permissionData?.includes(permission);
      }
      return false;
    },
    [permissionData] // Recompute only when permissionData changes
  );

  // Logout logic
  const logout = () => {
    return new Promise<void>((resolve) => {
      Cookies.remove("Authorization");
      localStorage.removeItem("isAdministrator");
      sessionStorage.removeItem("permissions");
      window.location.href = "/login";
      resolve();
    });
  };

  // Memoize the context value to prevent unnecessary re-renders
  const contextValue = useMemo(
    () => ({
      isAuthenticated,
      logout,
      checkIsAuthenticated: () => isAuthenticated,
      checkPermission,
      isPermissionsLoading,
    }),
    [isAuthenticated, checkPermission, isPermissionsLoading] // Dependencies
  );

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
