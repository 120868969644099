import ApiUrl from "./api-url";
import axios, { isCancel } from 'axios';
import {ApiHeaderOptions} from "./api-header-options";
import { users } from "./user-service";
import { useErrorStore } from "@/components/context/ErrorContext";

const PermissionGroups = {
    list: async () => {
        const url = `${ApiUrl}/v1/core/groups/`;
        return await axios.get(url, ApiHeaderOptions()).then((response) => {
            return response.data;
        }).catch((error) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                const { setError } = useErrorStore.getState();
                setError(error?.message + "permission group", "high");
                console.log('Form Error', error);
                throw error;
            }
        });
    },
    get: async (id: any) => {
        const url = `${ApiUrl}/v1/core/groups/${id}/`;
        return await axios.get(url, ApiHeaderOptions()).then((response) => {
            return response.data;
        }).catch((error) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                const { setError } = useErrorStore.getState();
                setError(error?.message + "permission group", "high");
                console.log('Form Error', error);
                throw error;
            }
        });
    },
    create: async (data: any) => {
        const url = `${ApiUrl}/v1/core/groups/`;
        return await axios.post(url, data, ApiHeaderOptions()).then((response) => {
            return response.data;
        }).catch((error) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                const { setError } = useErrorStore.getState();
                setError(error?.message + "permission group", "medium");
                console.log('Form Error', error);
                throw error;
            }
        });
    },
    update: async (id: any, data: any) => {
        const url = `${ApiUrl}/v1/core/groups/${id}/`;
        return await axios.put(url, data, ApiHeaderOptions()).then((response) => {
            return response.data;
        }).catch((error) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                const { setError } = useErrorStore.getState();
                setError(error?.message + "permission group", "medium");
                console.log('Form Error', error);
                throw error;
            }
        });
    },
};

const Permissions = {
    list: async () => {
        const url = `${ApiUrl}/v1/core/permissions/`;
        return await axios.get(url, ApiHeaderOptions()).then((response) => {
            return response.data;
        }).catch((error) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                const { setError } = useErrorStore.getState();
                setError(error?.message + "permission", "high");
                console.log('Form Error', error);
                throw error;
            }
        });
    },
    get: async (id: any) => {
        const url = `${ApiUrl}/v1/core/permissions/${id}/`;
        return await axios.get(url, ApiHeaderOptions()).then((response) => {
            return response.data;
        }).catch((error) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                const { setError } = useErrorStore.getState();
                setError(error?.message + "permission", "high");
                console.log('Form Error', error);
                throw error;
            }
        });
    },
    create: async (data: any) => {
        const url = `${ApiUrl}/v1/core/permissions/`;
        return await axios.post(url, data, ApiHeaderOptions()).then((response) => {
            return response.data;
        }).catch((error) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                const { setError } = useErrorStore.getState();
                setError(error?.message + "permission", "medium");
                console.log('Form Error', error);
                throw error;
            }
        });
    },
    update: async (id: any, data: any) => {
        const url = `${ApiUrl}/v1/core/permissions/${id}/`;
        return await axios.put(url, data, ApiHeaderOptions()).then((response) => {
            return response.data;
        }).catch((error) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                const { setError } = useErrorStore.getState();
                setError(error?.message + "permission", "medium");
                console.log('Form Error', error);
                throw error;
            }
        });
    },
};

export { PermissionGroups, Permissions };